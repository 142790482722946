
import React, { useState } from "react";
import ReservedTable from "./Reserverd";
import { Nav } from "react-bootstrap";
import RequestIn from "./RequestIn";


const StoreTableReservationPage = () => {
  const [requestIn, setRequestIn] = useState(true);
  const [reserve, setReserve] = useState(false);

  const handleRequestIn = () => {
    setRequestIn(true);
    setReserve(false);
  };
  const handleReserve = () => {
    setReserve(true);
    setRequestIn(false);
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-12 row">
          <h4 className="cate-title mb-sm-3 mb-2 mt-xl-0 mt-3 col-6 ">
          Reservation Details
          </h4>
          <nav className="order-tab col-6 ">
            <Nav className="nav-tabs" defaultActiveKey="request">
              <Nav.Link
                eventKey="request"
                id="nav-order-tab"
                onClick={handleRequestIn}
              >
                Request in
              </Nav.Link>
              <Nav.Link
                eventKey="Prepared"
                id="nav-prepared-tab"
                onClick={handleReserve}
              >
                Reserved
              </Nav.Link>
            </Nav>
          </nav>
          <div className="card border-0">
            {requestIn && <RequestIn />}

            {reserve && <ReservedTable />}

          </div>
        </div>
      </div>
    </>
  );
};
export default StoreTableReservationPage;
