import React, { useReducer } from "react";



function StorePartnerHelpAndSupport() {
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className=" ">
                <div className="">
                  <div class="help-support">
                    <h2 className="">Help & Support</h2>
                    <p>
                      Welcome to the Fringale Delivery Admin Help & Support section.
                      Here, you can find answers to commonly asked questions and
                      get assistance for any issues you encounter.
                    </p>

                    <h3 className="mt-4">Frequently Asked Questions (FAQs)</h3>
                    <ul>
                      <li className="mt-3">
                        <strong>
                          How do I add a new restaurant to the platform?
                        </strong>
                        <br />
                        To add a new restaurant, navigate to the Restaurants
                        section in the admin dashboard. Click on the "Add New
                        Restaurant" button and fill in the required information.
                      </li>
                      <li className="mt-3">
                        <strong>
                          What should I do if a delivery order is delayed?
                        </strong>
                        <br />
                        If a delivery order is delayed, first check the order
                        status and contact the delivery driver for updates. If
                        needed, contact the customer to inform them about the
                        delay.
                      </li>
                      <li className="mt-3">
                        <strong>
                          How can I change the delivery fee for a specific
                          restaurant?
                        </strong>
                        <br />
                        To change the delivery fee for a restaurant, go to the
                        Restaurants section, select the desired restaurant, and
                        then edit the delivery fee in the restaurant settings.
                      </li>
                      <li className="mt-3">
                        <strong>
                          What payment methods are supported on the platform?
                        </strong>
                        <br />
                        Our platform supports various payment methods, including
                        credit/debit cards, digital wallets, and cash on
                        delivery. You can manage payment settings in the Admin
                        Settings section.
                      </li>
                      <li className="mt-3">
                        <strong>
                          How do I view the order history for a specific
                          customer?
                        </strong>
                        <br />
                        To view the order history for a customer, search for the
                        customer's name or email in the Customers section of the
                        admin dashboard. You'll find a list of their previous
                        orders.
                      </li>
                      <li className="mt-3">
                        <strong>
                          What happens if a restaurant fails to fulfill an
                          order?
                        </strong>
                        <br />
                        If a restaurant fails to fulfill an order, you should
                        contact the restaurant directly to resolve the issue.
                        Meanwhile, inform the customer about the delay and
                        provide assistance as necessary.
                      </li>
                      <li className="mt-3">
                        <strong>
                          How do I update the menu items for a restaurant?
                        </strong>
                        <br />
                        To update menu items for a restaurant, navigate to the
                        Restaurants section, select the restaurant, and then
                        edit the menu items in the restaurant's menu settings.
                      </li>
                      <li className="mt-3">
                        <strong>
                          What should I do if a customer reports a missing item
                          in their order?
                        </strong>
                        <br />
                        If a customer reports a missing item, apologize for the
                        inconvenience and offer a refund or replacement for the
                        missing item. You can manage refunds in the Orders
                        section.
                      </li>
                      <li className="mt-3">
                        <strong>How do I handle a customer complaint?</strong>
                        <br />
                        When handling a customer complaint, listen attentively
                        to their concerns, apologize for any inconvenience, and
                        offer a solution to resolve the issue. Maintain
                        professionalism and empathy throughout the interaction.
                      </li>
                      <li className="mt-3">
                        <strong>
                          How do I contact technical support for assistance?
                        </strong>
                        <br />
                        If you encounter technical issues or need further
                        assistance beyond what's covered in the FAQs, please
                        contact our technical support team via email or phone.
                      </li>
                    </ul>

                    <h3 className="mt-4">Contact Us</h3>
                    <p>
                      If you need further assistance or have any questions not
                      covered in the FAQ section, please don't hesitate to
                      contact our support team.
                    </p>
                    <p>Email: support@fringale.com</p>
                    <p>Phone: 1-800-555-1234</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default StorePartnerHelpAndSupport;
