import React, { useReducer, useRef, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Dropdown, Modal, Tab } from "react-bootstrap";

import pic3 from "../../../../../images/popular-img/pic-3.jpg";
import pic1 from "../../../../../images/popular-img/pic-1.jpg";
import pic2 from "../../../../../images/popular-img/pic-2.jpg";
import pic4 from "../../../../../images/popular-img/pic-4.jpg";
import DateRangePicker from "react-bootstrap-daterangepicker";
const storeImage =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXwm8X2Uhvicl3bL8seMLO5mhe8BoobjdYP9A_nZ11ew&s";

//const init =  false;
function reducer(state, action) {
  if (action.type === "addCoupon") {
    return { ...state, addCoupon: !state.addCoupon };
  }
}

const Coupons = [
  {
    CouponID: "100001",
    CouponCode: "PIZZA10",
    Description: "10% off on all pizzas",
    DiscountValue: "10%",
    MinimumOrderValue: "20",
    StartDate: "2024-05-01",
    ExpiryDate: "2024-05-31",
    CreatedAt: "2024-04-01",
    Status: "Active",
  },
  {
    CouponID: "100002",
    CouponCode: "BURGER5",
    Description: "$5 off on orders over $25",
    DiscountValue: "$5",
    MinimumOrderValue: "25",
    StartDate: "2024-06-01",
    ExpiryDate: "2024-06-30",
    CreatedAt: "2024-05-01",
    Status: "Inactive",
  },
  {
    CouponID: "100003",
    CouponCode: "DRINKS15",
    Description: "15% off on all drinks",
    DiscountValue: "15%",
    MinimumOrderValue: "10",
    StartDate: "2024-07-01",
    ExpiryDate: "2024-07-31",
    CreatedAt: "2024-06-01",
    Status: "Active",
  },
  {
    CouponID: "100004",
    CouponCode: "DESSERT20",
    Description: "20% off on desserts",
    DiscountValue: "20%",
    MinimumOrderValue: "15",
    StartDate: "2024-08-01",
    ExpiryDate: "2024-08-31",
    CreatedAt: "2024-07-01",
    Status: "Active",
  },
  {
    CouponID: "100005",
    CouponCode: "WELCOME10",
    Description: "10% off on first order for new users",
    DiscountValue: "10%",
    MinimumOrderValue: "30",
    StartDate: "2024-09-01",
    ExpiryDate: "2024-09-30",
    CreatedAt: "2024-08-01",
    Status: "Inactive",
  },
];

const ViewMenuCoupons = () => {
  const [state, dispatch] = useReducer(reducer, false);
  const [storePicture, setStorePicture] = useState(null);
  const storePictureRef = useRef(null);
  const navigate = useNavigate();

  const chackboxFun = (type) => {
    setTimeout(() => {
      const checkbox = document.querySelectorAll(
        ".application_sorting_1 input"
      );
      const motherCheckBox = document.querySelector(".sorting_asc input");
      for (let i = 0; i < checkbox.length; i++) {
        const element = checkbox[i];
        if (type === "all") {
          if (motherCheckBox.checked) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        } else {
          if (!element.checked) {
            motherCheckBox.checked = false;
            break;
          } else {
            motherCheckBox.checked = true;
          }
        }
      }
    }, 200);
  };

  const DropdownBlog = () => {
    return (
      <>
        <Dropdown className="">
          <Dropdown.Toggle
            as="div"
            className="btn-link i-false"
            style={{ cursor: "pointer" }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                stroke="#262626"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                stroke="#262626"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                stroke="#262626"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => dispatch({ type: "addCoupon" })}>
              Edit
            </Dropdown.Item>
            <Dropdown.Item>Delete</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const handleStorePictureChange = (event) => {
    event.preventDefault();
    const file = storePictureRef.current.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setStorePicture(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteStorePicture = () => {
    setStorePicture(null);
    storePictureRef.current.value = null;
  };

  return (
    <>
      <Tab.Container defaultActiveKey="List">
        <div className="row justify-content-between mb-4">
          <div className="col input-group search-area2 style-1">
            <span className="input-group-text p-0">
              <Link to={"#"}>
                <svg
                  className="me-1"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                    fill="#FC8019"
                  />
                </svg>
              </Link>
            </span>
            <input
              type="text"
              className="form-control p-0"
              placeholder="Search..."
            />
          </div>
          <div className="col ">
            <button
              onClick={() => navigate(-1)}
              type="button"
              className=" ms-3 float-end btn btn-primary mt-3 mt-sm-0"
            >
              <i class="fa-solid fa-angle-left"></i> Back
            </button>
            <button
              onClick={() => dispatch({ type: "addCoupon" })}
              type="button"
              className=" float-end btn btn-primary mt-3 mt-sm-0"
            >
              Add Coupon
            </button>
          </div>
        </div>

        <Tab.Content>
          <Tab.Pane eventKey="List">
            <div className="card h-auto">
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table
                    className="table table-list i-table style-1 mb-4 border-0"
                    id="guestTable-all3"
                  >
                    <thead>
                      <tr>
                        <th className="bg-none sorting_asc">
                          <div className="form-check style-3">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="checkAll"
                              onClick={() => chackboxFun("all")}
                            />
                          </div>
                        </th>
                        <th>Coupon Code</th>
                        <th>Description</th>
                        <th>Discount</th>
                        <th>Min Order</th>
                        <th>Start Date</th>
                        <th>Expiry Date</th>
                        <th>Created At</th>
                        <th>Status</th>
                        <th>Action</th>
                        <th className="bg-none"></th>
                        <th className="bg-none"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {Coupons.map((item, i) => (
                        <tr>
                          <td className="application_sorting_1">
                            <div className="form-check style-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                onClick={() => chackboxFun()}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="media-bx d-flex py-3  align-items-center">
                              <div>
                                <h5 className="mb-0">{item?.CouponCode}</h5>
                                <p className="mb-0">#{item?.CouponID}</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div>
                              <p className="mb-0">{item?.Description}</p>
                            </div>
                          </td>
                          <td>
                            <div>
                              <p className="mb-0">{item?.DiscountValue}</p>
                            </div>
                          </td>
                          <td>
                            <div className="">
                              <p className="mb-0">${item?.MinimumOrderValue}</p>
                            </div>
                          </td>
                          <td>
                            <div>
                              <p className="">{item?.StartDate}</p>
                            </div>
                          </td>
                          <td>
                            <div>
                              <p className="">{item?.ExpiryDate}</p>
                            </div>
                          </td>
                          <td>
                            <div>
                              <p className="">{item?.CreatedAt}</p>
                            </div>
                          </td>
                          <td>
                            <div className="">
                              <i
                                className={`fa fa-circle text-${
                                  item?.Status === "Active"
                                    ? "success"
                                    : "danger"
                                } me-1`}
                              ></i>{" "}
                              {item?.Status}
                            </div>
                          </td>
                          <td>
                            <DropdownBlog />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>

      <Modal
        className="modal fade"
        show={state.addCoupon}
        onHide={() => dispatch({ type: "addCoupon" })}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Add Coupon
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => dispatch({ type: "addCoupon" })}
          ></button>
        </div>
        <div className="modal-body">
          <form>
          <div className="d-flex align-items-center veg justify-content-center">
              <div className="form-check me-3 ">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                />
                <label className="form-check-label" for="flexRadioDefault1">
                  Inactive
                </label>
              </div>
              <div className="form-check style-1">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  checked
                />
                <label className="form-check-label" for="flexRadioDefault2">
                  Active
                </label>
              </div>
            </div>
            <div className="modal-inside">
              <label for="exampleInputText" className="form-label">
                Coupon Code
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputText"
                placeholder=""
              />
            </div>
            <div className="row">
              <div className="col-xl-6">
                <div className="modal-inside">
                  <label for="exampleInputnumber" className="form-label mb-2">
                    Discount Value
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="exampleInputnumber"
                  />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="modal-inside">
                  <label for="exampleInputnumber-2" className="form-label mb-2">
                    Minimum Value
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputnumber-2"
                  />
                </div>
              </div>
            </div>

            <div className="modal-inside">
                  <label for="exampleInputnumber" className="form-label mb-2">
                    Start Date and Expiry Date
                  </label>
                  <DateRangePicker
                      initialSettings={{ startDate: '10/5/2022', endDate: '3/6/2022' }}
                    >
                      <input type="text" className="form-control input-daterange-timepicker" />
                    </DateRangePicker>
                </div>
            <div className="modal-inside">
              <label for="exampleInputEmail1" className="form-label mb-2">
                Description
              </label>
              <textarea
                className="form-control h-auto"
                // id="val-suggestions"
                // name="val-suggestions"
                rows="4"
                placeholder=""
              />
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => dispatch({ type: "addCoupon" })}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => dispatch({ type: "addCoupon" })}
          >
            Update
          </button>
        </div>
      </Modal>
    </>
  );
};
export default ViewMenuCoupons;
