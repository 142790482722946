import React, { useState } from "react";
import OrderIn from "./OrderIn";
import PrepareOrders from "./PrepareOrders";
import CompleteOrders from "./CompleteOrder";
import { Nav } from "react-bootstrap";


const StoreOrderPage = () => {
  const [orderIn, setOrderIn] = useState(true);
  const [prepare, setPrepare] = useState(false);
  const [complete, setComplete] = useState(false);

  const handleOrderIn = () => {
    setOrderIn(true);
    setPrepare(false);
    setComplete(false);
  };
  const handlePrepare = () => {
    setPrepare(true);
    setOrderIn(false);
    setComplete(false);
  };
  const handleComplete = () => {
    setComplete(true);
    setOrderIn(false);
    setPrepare(false);
  };
  return (
    <>
      <div className="row">
        <div className="col-xl-12 row">
          <h4 className="cate-title mb-sm-3 mb-2 mt-xl-0 mt-3 col-6 ">
            Order Details
          </h4>
          <nav className="order-tab col-6 ">
            <Nav className="nav-tabs" defaultActiveKey="Order">
              <Nav.Link
                eventKey="Order"
                id="nav-order-tab"
                onClick={handleOrderIn}
              >
                Order in
              </Nav.Link>
              <Nav.Link
                eventKey="Prepared"
                id="nav-prepared-tab"
                onClick={handlePrepare}
              >
                Prepared
              </Nav.Link>
              <Nav.Link
                eventKey="Delivered"
                id="nav-delivered-tab"
                onClick={handleComplete}
              >
                Delivered
              </Nav.Link>
            </Nav>
          </nav>
          <div className="card border-0">
            {orderIn && <OrderIn />}

            {prepare && <PrepareOrders />}

            {complete && <CompleteOrders />}
          </div>
        </div>
      </div>
    </>
  );
};
export default StoreOrderPage;
