import React, { Fragment, useReducer } from "react";
import { Button, Dropdown, Modal, Tab, Nav } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import LightGallery from "lightgallery/react";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

//** Import Image */

import review1 from "../../../../../images/resturent-review/pic-1.jpg";
import review2 from "../../../../../images/resturent-review/pic-2.jpg";
import review3 from "../../../../../images/resturent-review/pic-3.jpg";
import chatimg1 from "../../../../../images/chat-img/pic-1.jpg";
import chatimg2 from "../../../../../images/chat-img/pic-2.jpg";
import chatimg3 from "../../../../../images/chat-img/pic-3.jpg";
import coverPicture from "../../../../../images/profile/cover.jpg"


const initialState = false;
const reducer = (state, action) => {
  switch (action.type) {
    case "sendMessage":
      return { ...state, sendMessage: !state.sendMessage };
    case "postModal":
      return { ...state, post: !state.post };
    case "linkModal":
      return { ...state, link: !state.link };
    case "cameraModal":
      return { ...state, camera: !state.camera };
    case "replyModal":
      return { ...state, reply: !state.reply };
    default:
      return state;
  }
};

const SingleStoreDetailsPage = () => {
 
  return (
    <Fragment>
      {/* <PageTitle activeMenu="Profile" motherMenu="App" /> */}

      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content ">
                <div className="cover-photo rounded" style={{backgroundImage:`url(${coverPicture})`}}></div>
              </div>
              <div className="profile-info">
                <div className="profile-photo">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFVtbY8wgW2h6EZaYfUUHhyt72iGpBP1KdPrCbPXTiAw&s"
                    className="img-fluid rounded-circle"
                    alt="profile"
                  />
                </div>
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">Delicious Delights</h4>
                    <p>Store Name</p>
                  </div>
                  <Dropdown className="ms-auto">
                    <Dropdown.Toggle
                      variant="primary"
                      className="btn btn-primary light sharp i-false"
                    >
                      <Link to="/store/details?type=multi">Switch Multi Store</Link>
                    </Dropdown.Toggle>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-4">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="profile-statistics">
                    <div className="text-center">
                      <div className="row">
                        <div className="col">
                          <h3 className="m-b-0">150</h3>
                          <span>Follower</span>
                        </div>
                        <div className="col">
                          <h3 className="m-b-0">140</h3> <span>Place Stay</span>
                        </div>
                        <div className="col">
                          <h3 className="m-b-0">45</h3> <span>Reviews</span>
                        </div>
                      </div>
                      {/* <div className="mt-4">
											<Link	className="btn btn-primary mb-1 me-1">Follow</Link>
										<Button as="a" href="#" className="btn btn-primary mb-1 ms-1" onClick={() => dispatch({type:'sendMessage'})}>Send Message</Button>
										</div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h5 className="text-primary">Contact Information</h5>
                </div>
                <div className="card-body pt-3">
                  <div className="profile-news">
                    <div className="media pt-2 pb-2">
                      <div className="media-body">
                        <h5 className="mb-0">
                          <Link className="text-black">+1 (123) 456-7890</Link>
                        </h5>
                        <p className="mb-0">Phone Number</p>
                      </div>
                    </div>
                    <div className="media pt-2 pb-2">
                      <div className="media-body">
                        <h5 className="mb-0">
                          <Link className="text-black">
                            info@deliciousdelights.com
                          </Link>
                        </h5>
                        <p className="mb-0">Email Address</p>
                      </div>
                    </div>
                    <div className="media pt-2 pb-2">
                      <div className="media-body">
                        <h5 className="mb-0">
                          <Link className="text-black">
                            123 Main Street, Cityville, State, ZIP
                          </Link>
                        </h5>
                        <p className="mb-0">Physical Address</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-8">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <Tab.Container defaultActiveKey="About">
                    <Nav as="ul" className="nav nav-tabs">
                      <Nav.Item as="li" i className="nav-item">
                        <Nav.Link to="#about-me" eventKey="About">
                          About
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li" className="nav-item">
                        <Nav.Link to="#profile-settings" eventKey="Setting">
                          Setting
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane id="about-me" eventKey="About">
                        <div className="profile-about-me">
                          <div className="pt-4 border-bottom-1 pb-3">
                            <h4 className="text-primary">About Store</h4>
                            <p className="mb-2">
                              Welcome to{" "}
                              <span className="fw-bold">
                                Delicious Delights
                              </span>
                              , where passion meets flavor. We're dedicated to
                              crafting exceptional dishes with the freshest
                              ingredients. Join us on our flavorful journey and
                              let's create unforgettable culinary experiences
                              together!
                            </p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-12">
                            <div className="">
                              <h3 className=" cate-title font-w700 mb-4">
                                Our Team
                              </h3>
                              <Tab.Container defaultActiveKey="Grid">
                                <div className="    ">
                                  <div></div>
                                  <Tab.Content>
                                    <Tab.Pane eventKey="Grid">
                                      <div className="row">
                                        <div className="col-xl-6 col-xxl-6 col-sm-6">
                                          <div className="card">
                                            <div className="card-body">
                                              <div className="restro-review d-flex align-items-center border-bottom mb-4  pb-4">
                                                <img src={review1} alt="" />
                                                <div>
                                                  <h4 className="font-w500">
                                                    Michael Rodriguez
                                                  </h4>
                                                  <span>
                                                    Employed since 2020
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="recent-review d-flex align-items-center">
                                                <div>
                                                  <h4 className="font-w500 mb-0">
                                                    Operations Manager
                                                  </h4>
                                                </div>
                                              </div>
                                              <div></div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-xl-6 col-xxl-6 col-sm-6">
                                          <div className="card">
                                            <div className="card-body">
                                              <div className="restro-review d-flex align-items-center border-bottom mb-4  pb-4">
                                                <img src={review2} alt="" />
                                                <div>
                                                  <h4 className="font-w500">
                                                    Sarah Johnson
                                                  </h4>
                                                  <span>
                                                    Employed since 2020
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="recent-review d-flex align-items-center">
                                                <div>
                                                  <h4 className="font-w500 mb-0">
                                                    Head Chef
                                                  </h4>
                                                </div>
                                              </div>
                                              <div></div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-xl-6 col-xxl-6 col-sm-6">
                                          <div className="card">
                                            <div className="card-body">
                                              <div className="restro-review d-flex align-items-center border-bottom mb-4  pb-4">
                                                <img src={review3} alt="" />
                                                <div>
                                                  <h4 className="font-w500">
                                                    Emily Davis
                                                  </h4>
                                                  <span>
                                                    Employed since 2020
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="recent-review d-flex align-items-center">
                                                <div>
                                                  <h4 className="font-w500 mb-0">
                                                    Customer Experience
                                                    Specialist
                                                  </h4>
                                                </div>
                                              </div>
                                              <div></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Tab.Pane>
                                    <div className="d-flex align-items-center justify-content-sm-between justify-content-center flex-wrap pagination-bx">
                                      <div className="mb-sm-0 mb-3 pagination-title">
                                        <p className="mb-0">
                                          <span>Showing 1-5</span> from{" "}
                                          <span>100</span> data
                                        </p>
                                      </div>
                                      <nav>
                                        <ul className="pagination pagination-gutter">
                                          <li className="page-item page-indicator">
                                            <Link
                                              to={"#"}
                                              className="page-link"
                                            >
                                              <i className="la la-angle-left"></i>
                                            </Link>
                                          </li>
                                          <li className="page-item active">
                                            <Link
                                              to={"#"}
                                              className="page-link"
                                            >
                                              1
                                            </Link>
                                          </li>
                                          <li className="page-item">
                                            <Link
                                              to={"#"}
                                              className="page-link"
                                            >
                                              2
                                            </Link>
                                          </li>

                                          <li className="page-item">
                                            <Link
                                              to={"#"}
                                              className="page-link"
                                            >
                                              3
                                            </Link>
                                          </li>
                                          <li className="page-item page-indicator">
                                            <Link
                                              to={"#"}
                                              className="page-link"
                                            >
                                              <i className="la la-angle-right"></i>
                                            </Link>
                                          </li>
                                        </ul>
                                      </nav>
                                    </div>
                                  </Tab.Content>
                                </div>
                              </Tab.Container>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane id="profile-settings" eventKey="Setting">
                        <div className="pt-3">
                          <div className="settings-form">
                            <h4 className="text-primary">Store Setting</h4>
                            <form onSubmit={(e) => e.preventDefault()}>
                              <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                  <label className="form-label">Store</label>
                                  <input
                                    type="text"
                                    placeholder="Store name"
                                    className="form-control"
                                  />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                  <label className="form-label">Phone</label>
                                  <input
                                    type="tel"
                                    placeholder="Phone Number"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                  <label className="form-label">Email</label>
                                  <input
                                    type="email"
                                    placeholder="Email"
                                    className="form-control"
                                  />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                  <label className="form-label">Password</label>
                                  <input
                                    type="password"
                                    placeholder="Password"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="form-group mb-3">
                                <label className="form-label">Address</label>
                                <input
                                  type="text"
                                  placeholder="1234 Main St"
                                  className="form-control"
                                />
                              </div>

                              <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                  <label className="form-label">City</label>
                                  <input type="text" className="form-control" />
                                </div>
                                <div className="form-group mb-3 col-md-4">
                                  <label className="form-label">State</label>
                                  <select
                                    className="form-control"
                                    id="inputState"
                                    defaultValue="option-1"
                                  >
                                    <option value="option-1">Choose...</option>
                                    <option value="option-2">Option 1</option>
                                    <option value="option-3">Option 2</option>
                                    <option value="option-4">Option 3</option>
                                  </select>
                                </div>
                                <div className="form-group mb-3 col-md-2">
                                  <label className="form-label">Zip</label>
                                  <input type="text" className="form-control" />
                                </div>
                              </div>

                              <button className="btn btn-primary" type="submit">
                                Update
                              </button>
                            </form>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SingleStoreDetailsPage;
