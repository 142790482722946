import React, { Fragment, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SingleStoreDetailsPage from "./singleStore-";
import MultiStoreDetails from "./multiStore";

const StorePartnerDetailsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Function to update the query parameter in the URL
  const updateQueryParam = (type) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("type", type);
    navigate({ search: searchParams.toString() });
  };

  // Extracting the 'type' query parameter from the URL
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");

  useEffect(() => {
    if (!type) {
      updateQueryParam("single");
    }
  }, []);

  return (
    <Fragment>
      {type === "single" && <SingleStoreDetailsPage />}
      {type === "multi" && <MultiStoreDetails />}
    </Fragment>
  );
};

export default StorePartnerDetailsPage;
