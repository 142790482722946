const store1 =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQGauwoXxNMKXazkYfPednAJ8wUbBW6OaM0xByP4Avug&s";
const coverStore1 =
  "https://media-cldnry.s-nbcnews.com/image/upload/t_fit-1240w,f_auto,q_auto:best/rockcms/2024-01/best-trader-joe-food-cz-2x1-230123-d3e2bc.jpg";

const store2 =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREHQJGwSOCwSGytVf29u-MBmpJ-4KQGknqWHpsuNryUQ&s";
const coverStore2 =
  "https://scontent-bom2-1.xx.fbcdn.net/v/t1.18169-9/27336277_249799058894392_7997931342329644918_n.jpg?stp=c0.5000x0.5000f_dst-jpg_e15_p745x275_q63_tt1_u&efg=eyJ1cmxnZW4iOiJ1cmxnZW5fZnJvbV91cmwifQ&_nc_cid=0&_nc_ad=z-m&_nc_rml=0&_nc_ht=scontent-bom2-1.xx&_nc_cat=103&_nc_ohc=yZYtadmBrX4Q7kNvgEiYp1a&ccb=1-7&_nc_sid=5f2048&oh=00_AYAPSAj_KJ3QOXMTJuKFnjM1ymmKWH-CLOfDpsvllBMKEg&oe=666C1E96";

const store3 =
  "https://media.licdn.com/dms/image/D4D03AQHO_wMM8Hcjog/profile-displayphoto-shrink_800_800/0/1676536158600?e=2147483647&v=beta&t=AqyiwG55DMb1AbJxps8g_6QYBlHM8GqiGpDVsdKWrGY";

const store4 =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyaChrW2TeWB9XrYo3W5uYBfgDtdAGiBtssFF-m_i1kQ&s";

const Storesdata = [
  {
    id: 1,
    img: store1,
    coverPicture: coverStore1,
    storeName: "Joe's Groceries",
    storeEmail: "joesgroceries@example.com",
    address: "123 Main Street, Anytown, USA",
    contactInformation: "(555) 123-4567",
    staffMembers: [
      {
        name: "John Doe",
        position: "Manager",
        email: "john.doe@example.com",
        picture: "https://example.com/john_doe.jpg",
        joinDate: "2024-05-01",
      },
      {
        name: "Jane Smith",
        position: "Cashier",
        email: "jane.smith@example.com",
        picture: "https://example.com/jane_smith.jpg",
        joinDate: "2024-03-15",
      },
      {
        name: "Michael Johnson",
        position: "Stock Clerk",
        email: "michael.johnson@example.com",
        picture: "https://example.com/michael_johnson.jpg",
        joinDate: "2023-11-20",
      },
    ],
  },
  {
    id: 2,
    img: store2,
    coverPicture: coverStore2,
    storeName: "FreshMart",
    storeEmail: "freshmart@example.com",
    address: "789 Elm Avenue, Cityville, USA",
    contactInformation: "(555) 987-6543",
    staffMembers: [
      {
        name: "Emily Brown",
        position: "Manager",
        email: "emily.brown@example.com",
        picture: "https://example.com/emily_brown.jpg",
        joinDate: "2024-02-10",
      },
      {
        name: "Daniel Lee",
        position: "Cashier",
        email: "daniel.lee@example.com",
        picture: "https://example.com/daniel_lee.jpg",
        joinDate: "2024-01-03",
      },
      {
        name: "Sophia Garcia",
        position: "Stock Clerk",
        email: "sophia.garcia@example.com",
        picture: "https://example.com/sophia_garcia.jpg",
        joinDate: "2023-09-25",
      },
    ],
  },
  {
    id: 3,
    img: store3,
    storeName: "Green Leaf Organics",
    storeEmail: "greenleaforganics@example.com",
    address: "456 Oak Lane, Townsville, USA",
    contactInformation: "contact@greenleaf.com",
    staffMembers: [
      {
        name: "William Johnson",
        position: "Manager",
        email: "william.johnson@example.com",
        picture: "https://example.com/william_johnson.jpg",
        joinDate: "2024-04-05",
      },
      {
        name: "Olivia Martinez",
        position: "Cashier",
        email: "olivia.martinez@example.com",
        picture: "https://example.com/olivia_martinez.jpg",
        joinDate: "2024-02-15",
      },
      {
        name: "Noah Clark",
        position: "Stock Clerk",
        email: "noah.clark@example.com",
        picture: "https://example.com/noah_clark.jpg",
        joinDate: "2023-12-10",
      },
    ],
  },
  {
    id: 4,
    img: store4,
    coverPicture: "",
    storeName: "Smith's Market",
    storeEmail: "smithsmarket@example.com",
    address: "321 Pine Road, Villageton, USA",
    contactInformation: "(555) 555-5555",
    staffMembers: [
      {
        name: "Emma Wilson",
        position: "Manager",
        email: "emma.wilson@example.com",
        picture: "https://example.com/emma_wilson.jpg",
        joinDate: "2023-08-20",
      },
      {
        name: "James Taylor",
        position: "Cashier",
        email: "james.taylor@example.com",
        picture: "https://example.com/james_taylor.jpg",
        joinDate: "2023-07-01",
      },
      {
        name: "Ava Anderson",
        position: "Stock Clerk",
        email: "ava.anderson@example.com",
        picture: "https://example.com/ava_anderson.jpg",
        joinDate: "2023-05-12",
      },
    ],
  },
];

export { Storesdata };
