import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import pic1 from "../../../../images/chat-img/orders-img/pic-1.jpg";
import { Tab } from "react-bootstrap";
import swal from "sweetalert";
import moment from "moment";

const orderTab = [
  { order: "1", title: "Prepared", title2: "Delivered" },
  { order: "2", title: "Prepared", title2: "Delivered" },
  { order: "3", title: "Prepared", title2: "Delivered" },
  { order: "4", title: "Prepared", title2: "Delivered" },
  { order: "5", title: "Prepared", title2: "Delivered" },
  { order: "6", title: "Prepared", title2: "Delivered" },
];

const RequestIn = () => {
  const [currentTime, setCurrentTime] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000); // Update every second

    return () => clearInterval(interval);
  }, []);

  const timeAfterFiveHours = currentTime?.clone().add(5, "hours");

  // Determine the date string to display
  let dateString;
  if (timeAfterFiveHours.isSame(currentTime, "day")) {
    dateString = "Today";
  } else if (
    timeAfterFiveHours.isSame(currentTime.clone().add(1, "day"), "day")
  ) {
    dateString = "Tomorrow";
  } else {
    dateString = timeAfterFiveHours.format("YYYY-MM-DD");
  }
  return (
    <div className="row">
      <div className="col-xl-4">
        <div className="card">
          <Tab.Container defaultActiveKey="Order">
            <div className="card-body">
              <Tab.Content>
                <Tab.Pane eventKey="Order">
                  {orderTab.map((item, ind) => (
                    <div
                      className="orderin-bx d-flex align-items-center justify-content-between"
                      key={ind}
                    >
                      <div>
                        <h4>Request ID #{item.order}</h4>
                        <span>June 1, 2020, 08:22 AM</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <h4 className="text-primary mb-0">$202.00</h4>
                        <Link to={"#"} className="icon-bx">
                          <i className="fa-solid fa-angle-right"></i>
                        </Link>
                      </div>
                    </div>
                  ))}
                </Tab.Pane>
                <Tab.Pane eventKey="Prepared">
                  {orderTab.map((item, ind) => (
                    <div
                      className="orderin-bx d-flex align-items-center justify-content-between"
                      key={ind}
                    >
                      <div>
                        <h4>{item.title}</h4>
                        <span>June 1, 2020, 08:22 AM</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <h4 className="text-primary mb-0">$202.00</h4>
                        <Link to={"#"} className="icon-bx">
                          <i className="fa-solid fa-angle-right"></i>
                        </Link>
                      </div>
                    </div>
                  ))}
                </Tab.Pane>
                <Tab.Pane eventKey="Delivered">
                  {orderTab.map((item, ind) => (
                    <div
                      className="orderin-bx d-flex align-items-center justify-content-between"
                      key={ind}
                    >
                      <div>
                        <h4>{item.title2}</h4>
                        <span>June 1, 2020, 08:22 AM</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <h4 className="text-primary mb-0">$202.00</h4>
                        <Link to={"#"} className="icon-bx">
                          <i className="fa-solid fa-angle-right"></i>
                        </Link>
                      </div>
                    </div>
                  ))}
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      </div>
      <div className="col-xl-8">
        <div className="card border-0">
          <div className="card h-auto">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between border-bottom flex-wrap">
                <div className="mb-4">
                  <h4 className="font-w500">Reservation ID #1</h4>
                  <span>June 1, 2020, 08:22 AM</span>
                </div>
                <div className="orders-img d-flex mb-4">
                  <img src={pic1} alt="" />
                  <div>
                    <h6 className="font-w500">Ruby Roben</h6>
                    <span>User since 2020</span>
                  </div>
                </div>
              </div>
              <div className="row border-bottom pb-2">
                <div className="col-xl-5">
                  <div className="address-bx mt-4">
                    <div className="d-flex align-items-center mb-2">
                      <h4 className="mb-0 font-w700">
                        Guest's Booking Preferences
                      </h4>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt.{" "}
                    </p>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <span>Contact</span>
                    <span>Number of guests</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <h6 className="mb-0">+1 123-456-789 </h6>
                    <h6 className="mb-0">5 </h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span>Estimation Time </span>
                    <span>Reservation Fee</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="mb-0">
                      {timeAfterFiveHours.format("h:mm A")}, {dateString}{" "}
                    </h6>
                    <h6 className="mb-0">Free</h6>
                  </div>
                </div>
                <div className="col-xl-2"></div>
              </div>

              <hr style={{ opacity: "0.7" }} />
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="font-w500 mb-0">Total</h4>
                <h4 className="cate-title text-primary">Free</h4>
              </div>
            </div>
          </div>
          <div className="text-end">
            <Link to={"#"} className="btn btn-outline-danger me-sm-4 me-2">
              Reject
            </Link>
            <Link
              onClick={() =>
                swal(
                  "Accepted!",
                  "Reservation successfuly accepted!",
                  "success"
                )
              }
              to={"#"}
              className="btn btn-primary"
            >
              Accept
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestIn;
