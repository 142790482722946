import React, { useState } from 'react';
import { PlusIcon, SearchIcon } from 'lucide-react'; // Ensure lucide-react is installed
import { Button, Modal, Form, Table } from 'react-bootstrap'; // Using Bootstrap components

const InventoryManagement = () => {
  const initialInventory = [
    { id: 1, name: 'Widget A', order: 'Fulfill', OrderId: 1000243211, reorderPoint: 149, trackingId: 'adhfbdhf345' },
    { id: 2, name: 'Gadget B', order: 'pending', OrderId: 2046798511, reorderPoint: 700, trackingId: 'bdfg345gdf' },
    { id: 3, name: 'Tool C', order: 'Fulfill', OrderId: 1209870012, reorderPoint: 990, trackingId: 'sdfg456dfg' },
    { id: 4, name: 'Part D', order: 'pending', OrderId: 2003456091, reorderPoint: 1200, trackingId: 'hjk678kl' },
    { id: 5, name: 'Component E', order: 'Fulfill', OrderId: 1000409816, reorderPoint: 300, trackingId: 'jkl789mno' },
  ];

  const [inventory, setInventory] = useState(initialInventory);
  const [searchTerm, setSearchTerm] = useState('');
  const [newItem, setNewItem] = useState({ name: '', order: '', OrderId: 0, reorderPoint: 0, trackingId: '' });
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  const filteredInventory = inventory.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.order.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOrderIdChange = (id, change) => {
    setInventory(inventory.map(item =>
      item.id === id ? { ...item, OrderId: Math.max(0, item.OrderId + change) } : item
    ));
  };

  const handleAddItem = () => {
    setInventory([...inventory, { id: inventory.length + 1, ...newItem }]);
    setNewItem({ name: '', order: '', OrderId: 0, reorderPoint: 0, trackingId: '' });
    setIsAddDialogOpen(false);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Inventory Management</h1>
      
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="position-relative">
          <SearchIcon className="position-absolute top-50 start-0 translate-middle-y ms-2" />
          <Form.Control
            type="text"
            placeholder="Search inventory..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="ps-5"
          />
        </div>
        <Button onClick={() => setIsAddDialogOpen(true)}>
          <PlusIcon className="me-2" />
          Add Item
        </Button>
      </div>

      {/* Add Item Modal */}
      <Modal show={isAddDialogOpen} onHide={() => setIsAddDialogOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Inventory Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={newItem.name}
                onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="order">
              <Form.Label>order</Form.Label>
              <Form.Control
                type="text"
                value={newItem.order}
                onChange={(e) => setNewItem({ ...newItem, order: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="OrderId">
              <Form.Label>OrderId</Form.Label>
              <Form.Control
                type="number"
                value={newItem.OrderId}
                onChange={(e) => setNewItem({ ...newItem, OrderId: parseInt(e.target.value) })}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="reorderPoint">
              <Form.Label>Reorder Point</Form.Label>
              <Form.Control
                type="number"
                value={newItem.reorderPoint}
                onChange={(e) => setNewItem({ ...newItem, reorderPoint: parseInt(e.target.value) })}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="trackingId">
              <Form.Label>Tracking ID</Form.Label>
              <Form.Control
                type="text"
                value={newItem.trackingId}
                onChange={(e) => setNewItem({ ...newItem, trackingId: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="" onClick={() => setIsAddDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleAddItem}>Add Item</Button>
        </Modal.Footer>
      </Modal>

      {/* Inventory Table */}
      <div className="overflow-x-auto">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>order Status</th>
              <th>OrderId</th>
              <th>Order Price</th>
              <th>Tracking ID</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredInventory.map((item) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.order}</td>
                <td>{item.OrderId}</td>
                <td>{item.reorderPoint}</td>
                <td>{item.trackingId}</td>
                <td>
                  <div className="d-flex align-items-center gap-2">
                    <Button size="sm" onClick={() => handleOrderIdChange(item.id, -1)}>-</Button>
                    <Button size="sm" onClick={() => handleOrderIdChange(item.id, 1)}>+</Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default InventoryManagement;
