// import { useState } from 'react';
// import { Button, FormControl, Table, Modal, InputGroup } from 'react-bootstrap';
// import { PlusIcon, SearchIcon } from 'lucide-react';
// import 'bootstrap/dist/css/bootstrap.min.css';


// // Define the roles and their permissions
// const roles = {
//   Manager: ['Menu', 'Orders', 'Staff', 'Inventory', 'Finance'],
//   Chef: ['Menu', 'Orders'],
//   Vendor: ['Inventory'],
//   Gatekeeper: ['Orders'],
//   Accountant: ['Finance']
// };

// // Mock data for initial staff members
// const initialStaff = [
//   { id: 1, name: 'John Doe', role: 'Manager', email: 'john@example.com', phone: '123-456-7890' },
//   { id: 2, name: 'Jane Smith', role: 'Chef', email: 'jane@example.com', phone: '234-567-8901' },
//   { id: 3, name: 'Bob Johnson', role: 'Vendor', email: 'bob@example.com', phone: '345-678-9012' },
// ];

// const StaffManagement = () => {
//   const [staff, setStaff] = useState(initialStaff);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [newStaff, setNewStaff] = useState({ name: '', role: '', email: '', phone: '' });
//   const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
//   const [selectedStaff, setSelectedStaff] = useState(null);

//   const filteredStaff = staff.filter(member =>
//     member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     member.email.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   const handleAddStaff = () => {
//     setStaff([...staff, { id: staff.length + 1, ...newStaff }]);
//     setNewStaff({ name: '', role: '', email: '', phone: '' });
//     setIsAddDialogOpen(false);
//   };

//   const handleSelectStaff = (staffMember) => {
//     setSelectedStaff(staffMember);
//   };

//   return (
//     <div className="container mx-auto p-4">
//       <h1 className="text-2xl font-bold mb-4">Staff Management</h1>

//       <div className="d-flex justify-content-between mb-4">
//         <div className="position-relative">
//           <InputGroup>
//             <InputGroup.Text><SearchIcon className="h-4 w-4 text-muted-foreground" /></InputGroup.Text>
//             <FormControl
//               type="text"
//               placeholder="Search staff..."
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//             />
//           </InputGroup>
//         </div>
//         <Button variant="primary" onClick={() => setIsAddDialogOpen(true)}>
//           <PlusIcon className="me-2 h-4 w-4" />
//           Add Staff
//         </Button>
//       </div>

//       <Modal show={isAddDialogOpen} onHide={() => setIsAddDialogOpen(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Add New Staff Member</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="mb-3">
//             <FormControl
//               placeholder="Name"
//               value={newStaff.name}
//               onChange={(e) => setNewStaff({ ...newStaff, name: e.target.value })}
//               className="mb-3"
//             />
//             <FormControl
//               placeholder="Email"
//               type="email"
//               value={newStaff.email}
//               onChange={(e) => setNewStaff({ ...newStaff, email: e.target.value })}
//               className="mb-3"
//             />
//             <FormControl
//               placeholder="Phone"
//               value={newStaff.phone}
//               onChange={(e) => setNewStaff({ ...newStaff, phone: e.target.value })}
//               className="mb-3"
//             />
//             <FormControl
//               as="select"
//               value={newStaff.role}
//               onChange={(e) => setNewStaff({ ...newStaff, role: e.target.value })}
//             >
//               <option value="">Select a role</option>
//               {Object.keys(roles).map((role) => (
//                 <option key={role} value={role}>{role}</option>
//               ))}
//             </FormControl>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={() => setIsAddDialogOpen(false)}>
//             Close
//           </Button>
//           <Button variant="primary" onClick={handleAddStaff}>
//             Add Staff Member
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       <div className="d-flex flex-wrap gap-4">
//         <div className="overflow-auto flex-grow-1">
//           <Table striped bordered hover>
//             <thead>
//               <tr>
//                 <th>Name</th>
//                 <th>Role</th>
//                 <th>Email</th>
//                 <th>Phone Number</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredStaff.map((member) => (
//                 <tr key={member.id} onClick={() => handleSelectStaff(member)} className="cursor-pointer">
//                   <td>{member.name}</td>
//                   <td>{member.role}</td>
//                   <td>{member.email}</td>
//                   <td>{member.phone}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>
//         </div>

//         <div>
//           <h2 className="text-xl font-semibold mb-2">Staff Details</h2>
//           {selectedStaff ? (
//             <div className="space-y-2">
//               <p><strong>Name:</strong> {selectedStaff.name}</p>
//               <p><strong>Role:</strong> {selectedStaff.role}</p>
//               <p><strong>Email:</strong> {selectedStaff.email}</p>
//               <p><strong>Phone:</strong> {selectedStaff.phone}</p>
//               <div>
//                 <h3 className="font-semibold mt-4 mb-2">Permissions:</h3>
//                 <ul>
//                   {roles[selectedStaff.role].map((permission) => (
//                     <li key={permission} className="d-flex align-items-center">
//                       <input type="checkbox" id={`permission-${permission}`} checked readOnly />
//                       <label htmlFor={`permission-${permission}`} className="ms-2">{permission}</label>
//                     </li>
//                   ))}
//                 </ul>
//               </div>
//             </div>
//           ) : (
//             <p>Select a staff member to view details</p>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default StaffManagement;





import { useState } from 'react';
import { Button, FormControl, Table, Modal, InputGroup, Dropdown } from 'react-bootstrap';
import { PlusIcon, SearchIcon } from 'lucide-react';
import 'bootstrap/dist/css/bootstrap.min.css';

// Define the roles and their permissions
const roles = {
  Manager: ['Menu', 'Orders', 'Staff', 'Inventory', 'Finance'],
  Chef: ['Menu', 'Orders'],
  Vendor: ['Inventory'],
  Gatekeeper: ['Orders'],
  Accountant: ['Finance']
};

// Mock data for initial staff members
const initialStaff = [
  { id: 1, name: 'John Doe', role: 'Manager', email: 'john@example.com', phone: '123-456-7890' },
  { id: 2, name: 'Jane Smith', role: 'Chef', email: 'jane@example.com', phone: '234-567-8901' },
  { id: 3, name: 'Bob Johnson', role: 'Vendor', email: 'bob@example.com', phone: '345-678-9012' },
];

const StaffManagement = () => {
  const [staff, setStaff] = useState(initialStaff);
  const [searchTerm, setSearchTerm] = useState('');
  const [newStaff, setNewStaff] = useState({ name: '', role: '', email: '', phone: '' });
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);

  const filteredStaff = staff.filter(member =>
    member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    member.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddStaff = () => {
    setStaff([...staff, { id: staff.length + 1, ...newStaff }]);
    setNewStaff({ name: '', role: '', email: '', phone: '' });
    setIsAddDialogOpen(false);
  };

  const handleSelectStaff = (staffMember) => {
    setSelectedStaff(staffMember);
  };

  const handleRoleChange = (newRole) => {
    if (selectedStaff) {
      const updatedStaff = staff.map(member =>
        member.id === selectedStaff.id ? { ...member, role: newRole } : member
      );
      setStaff(updatedStaff);
      setSelectedStaff({ ...selectedStaff, role: newRole });
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Staff Management</h1>

      <div className="d-flex justify-content-between mb-4">
        <div className="position-relative">
          <InputGroup>
            <InputGroup.Text><SearchIcon className="h-4 w-4 text-muted-foreground" /></InputGroup.Text>
            <FormControl
              type="text"
              placeholder="Search staff..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
        </div>
        <Button variant="primary" onClick={() => setIsAddDialogOpen(true)}>
          <PlusIcon className="me-2 h-4 w-4" />
          Add Staff
        </Button>
      </div>

      <Modal show={isAddDialogOpen} onHide={() => setIsAddDialogOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Staff Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <FormControl
              placeholder="Name"
              value={newStaff.name}
              onChange={(e) => setNewStaff({ ...newStaff, name: e.target.value })}
              className="mb-3"
            />
            <FormControl
              placeholder="Email"
              type="email"
              value={newStaff.email}
              onChange={(e) => setNewStaff({ ...newStaff, email: e.target.value })}
              className="mb-3"
            />
            <FormControl
              placeholder="Phone"
              value={newStaff.phone}
              onChange={(e) => setNewStaff({ ...newStaff, phone: e.target.value })}
              className="mb-3"
            />
            <FormControl
              as="select"
              value={newStaff.role}
              onChange={(e) => setNewStaff({ ...newStaff, role: e.target.value })}
            >
              <option value="">Select a role</option>
              {Object.keys(roles).map((role) => (
                <option key={role} value={role}>{role}</option>
              ))}
            </FormControl>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="" onClick={() => setIsAddDialogOpen(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddStaff}>
            Add Staff Member
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="d-flex flex-wrap gap-4">
        <div className="overflow-auto flex-grow-1">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Role</th>
                <th>Email</th>
                <th>Phone Number</th>
              </tr>
            </thead>
            <tbody>
              {filteredStaff.map((member) => (
                <tr key={member.id} onClick={() => handleSelectStaff(member)} className="cursor-pointer">
                  <td>{member.name}</td>
                  <td>{member.role}</td>
                  <td>{member.email}</td>
                  <td>{member.phone}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <div>
          <h2 className="text-xl font-semibold mb-2">Staff Details</h2>
          {selectedStaff ? (
            <div className="space-y-2">
              <p><strong>Name:</strong> {selectedStaff.name}</p>
              <p><strong>Role:</strong> 
                <Dropdown onSelect={handleRoleChange}>
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                    {selectedStaff.role}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {Object.keys(roles).map((role) => (
                      <Dropdown.Item key={role} eventKey={role}>
                        {role}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </p>
              <p><strong>Email:</strong> {selectedStaff.email}</p>
              <p><strong>Phone:</strong> {selectedStaff.phone}</p>
              <div>
                <h3 className="font-semibold mt-4 mb-2">Permissions:</h3>
                <ul>
                  {roles[selectedStaff.role].map((permission) => (
                    <li key={permission} className="d-flex align-items-center">
                      <input type="checkbox" id={`permission-${permission}`} checked readOnly />
                      <label htmlFor={`permission-${permission}`} className="ms-2">{permission}</label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <p>Select a staff member to view details</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default StaffManagement;
