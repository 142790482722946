import React, { Fragment, useReducer, useRef, useState } from "react";
// import { Table, Pagination } from "react-bootstrap";
import { Storesdata } from "./tableData";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

const storeImage =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXwm8X2Uhvicl3bL8seMLO5mhe8BoobjdYP9A_nZ11ew&s";

  const storeCoverImage =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXwm8X2Uhvicl3bL8seMLO5mhe8BoobjdYP9A_nZ11ew&s";

//const init =  false;
function reducer(state, action) {
  if (action.type === "addStore") {
    return { ...state, addStore: !state.addStore };
  }
}

const MultiStoreDetails = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, false);

  const [storePicture, setStorePicture] = useState(null);
  const [storeCoverPicture, setStoreCoverPicture] = useState(null);

  const storePictureRef = useRef(null);
  const storeCoverPictureRef = useRef(null);

  const handleStorePictureChange = (event) => {
    event.preventDefault();
    const file = storePictureRef.current.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setStorePicture(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleStoreCoverPictureChange = (event) => {
    event.preventDefault();
    const file = storeCoverPictureRef.current.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setStoreCoverPicture(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteStorePicture = () => {
    setStorePicture(null);
    storePictureRef.current.value = null; 
  };
  const handleDeleteStoreCoverPicture = () => {
    setStorePicture(null);
    storeCoverPictureRef.current.value = null; 
  };
  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Stores</h4>
          <button
            type="button"
            className="btn btn-primary mt-3 mt-sm-0"
            onClick={() => dispatch({ type: "addStore" })}
          >
            Add New Store
          </button>
        </div>
        <div className="card-body">
          <div className="w-100 table-responsive">
            <div id="example_wrapper" className="dataTables_wrapper">
              <table id="example" className="display w-100 dataTable">
                <thead>
                  <tr role="row">
                    <th>ID</th>
                    <th>Store Name</th>
                    <th>Address</th>
                    <th>Contact Information</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Storesdata &&
                    Storesdata.map((item, i) => (
                      <tr key={i}>
                        <Fragment>
                          <td>
                            <img
                              className="rounded-circle"
                              width="40"
                              src={item?.img}
                              alt=""
                            />
                            <small className="fw-bold">#{item?.id}</small>
                          </td>

                          <td>{item?.storeName}</td>
                          <td>{item?.address}</td>
                          <td>{item?.contactInformation}</td>
                          <td>
                            <button
                              className="btn btn-primary light"
                              onClick={() =>
                                navigate(
                                  `/store/multi-store-details/${item?.id}`
                                )
                              }
                            >
                              View
                            </button>
                          </td>
                        </Fragment>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="modal fade"
        size="lg"
        show={state.addStore}
        onHide={() => dispatch({ type: "addStore" })}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Add Store
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => dispatch({ type: "addStore" })}
          ></button>
        </div>
        <div className="modal-body">
          <form>
            <div className="row">
              <div className="col-xl-4">
                {/* Store Picture */}
                <div className="modal-inside">
                  <label htmlFor="storePicture" className="form-label mb-2">
                    Store Picture
                  </label>
                  <div className="d-flex align-items-center mb-2">
                    <img
                      src={storePicture ? storePicture : storeImage}
                      alt="Store"
                      className="add-store-image-on-modal mr-2"
                    />
                    {storePicture && (
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={handleDeleteStorePicture}
                      >
                        Delete
                      </button>
                    )}
                  </div>
                  {!storePicture && (
                    <button
                      type="button"
                      className="btn btn-primary mb-2"
                      onClick={() => storePictureRef.current.click()}
                    >
                      Add Picture
                    </button>
                  )}
                  <input
                    type="file"
                    className="form-control d-none"
                    id="storePicture"
                    accept="image/*"
                    ref={storePictureRef}
                    onChange={handleStorePictureChange}
                  />
                </div>
              </div>
              <div className="col-xl-8">
              <div className="modal-inside">
                  <label htmlFor="storePicture" className="form-label mb-2">
                    Store Cover Picture
                  </label>
                  <div className="d-flex align-items-center mb-2">
                    <img
                      src={storeCoverPicture ? storeCoverPicture : storeCoverImage}
                      alt="Store"
                      className="add-store-image-on-modal mr-2"
                    />
                    {storeCoverPicture && (
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={handleDeleteStoreCoverPicture}
                      >
                        Delete
                      </button>
                    )}
                  </div>
                  {!storeCoverPicture && (
                    <button
                      type="button"
                      className="btn btn-primary mb-2"
                      onClick={() => storeCoverPictureRef.current.click()}
                    >
                      Add Picture
                    </button>
                  )}
                  <input
                    type="file"
                    className="form-control d-none"
                    id="storePicture"
                    accept="image/*"
                    ref={storeCoverPictureRef}
                    onChange={handleStoreCoverPictureChange}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6">
                <div className="modal-inside">
                  <label for="exampleInputText" className="form-label">
                    Store Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputText"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="modal-inside">
                  <label for="exampleInputnumber-3" className="form-label mb-2">
                    Phone Number
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="exampleInputnumber-3"
                  />
                </div>
              </div>
            </div>

            <div className="modal-inside">
              <label for="exampleInputEmail1" className="form-label mb-2">
                Email Address
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
              />
            </div>
            <div className="modal-inside">
              <label for="" className="form-label mb-2">
                Description
              </label>
              <textarea
                className="form-control h-auto"
                // id="val-suggestions"
                // name="val-suggestions"
                rows="4"
                placeholder=""
              />
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => dispatch({ type: "addStore" })}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => dispatch({ type: "addStore" })}
          >
            Save
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default MultiStoreDetails;
