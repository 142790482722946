import React, { useReducer, useRef, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Dropdown, Modal, Tab } from "react-bootstrap";

import pic3 from "../../../../../images/popular-img/pic-3.jpg";
import pic1 from "../../../../../images/popular-img/pic-1.jpg";
import pic2 from "../../../../../images/popular-img/pic-2.jpg";
import pic4 from "../../../../../images/popular-img/pic-4.jpg";
const storeImage =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXwm8X2Uhvicl3bL8seMLO5mhe8BoobjdYP9A_nZ11ew&s";

//const init =  false;
function reducer(state, action) {
  if (action.type === "editMenu") {
    return { ...state, editMenu: !state.editMenu };
  }
}
const ViewMenuTable = () => {
  const [state, dispatch] = useReducer(reducer, false);
  const [storePicture, setStorePicture] = useState(null);
  const storePictureRef = useRef(null);
  const navigate = useNavigate();

  const chackboxFun = (type) => {
    setTimeout(() => {
      const checkbox = document.querySelectorAll(
        ".application_sorting_1 input"
      );
      const motherCheckBox = document.querySelector(".sorting_asc input");
      for (let i = 0; i < checkbox.length; i++) {
        const element = checkbox[i];
        if (type === "all") {
          if (motherCheckBox.checked) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        } else {
          if (!element.checked) {
            motherCheckBox.checked = false;
            break;
          } else {
            motherCheckBox.checked = true;
          }
        }
      }
    }, 200);
  };

  const DropdownBlog = () => {
    return (
      <>
        <Dropdown className="">
          <Dropdown.Toggle
            as="div"
            className="btn-link i-false"
            style={{ cursor: "pointer" }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                stroke="#262626"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                stroke="#262626"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                stroke="#262626"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => dispatch({ type: "editMenu" })}>
              Edit
            </Dropdown.Item>
            <Dropdown.Item>Delete</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const handleStorePictureChange = (event) => {
    event.preventDefault();
    const file = storePictureRef.current.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setStorePicture(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteStorePicture = () => {
    setStorePicture(null);
    storePictureRef.current.value = null;
  };

  return (
    <>
      <Tab.Container defaultActiveKey="List">
        <div className="row justify-content-between mb-4">
          <div className="col input-group search-area2 style-1">
            <span className="input-group-text p-0">
              <Link to={"#"}>
                <svg
                  className="me-1"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                    fill="#FC8019"
                  />
                </svg>
              </Link>
            </span>
            <input
              type="text"
              className="form-control p-0"
              placeholder="Search..."
            />
          </div>
          <div className="col ">
            <button  onClick={() => navigate(-1)} type="button" className="float-end btn btn-primary mt-3 mt-sm-0">
            <i class="fa-solid fa-angle-left"></i> {" "} Back
            </button>
          </div>
        </div>

        <Tab.Content>
          <Tab.Pane eventKey="List">
            <div className="card h-auto">
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table
                    className="table table-list i-table style-1 mb-4 border-0"
                    id="guestTable-all3"
                  >
                    <thead>
                      <tr>
                        <th className="bg-none sorting_asc">
                          <div className="form-check style-3">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="checkAll"
                              onClick={() => chackboxFun("all")}
                            />
                          </div>
                        </th>
                        <th>Item & Item Name</th>
                        <th>Create_at</th>
                        <th>Updated_at</th>
                        <th>Price</th>
                        <th>Status</th>
                        <th>Action</th>
                        <th className="bg-none"></th>
                        <th className="bg-none"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="application_sorting_1">
                          <div className="form-check style-3">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              onClick={() => chackboxFun()}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <img
                              className="me-3 rounded-circle"
                              src={pic1}
                              alt="images"
                            />
                            <div>
                              <h5 className="mb-0">Fish Burger</h5>
                              <p className="mb-0">1x </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="mb-0">June 1, 2020, 08:22 AM</p>
                          </div>
                        </td>
                        <td>
                          <div className="">
                            <p className="mb-0">June 1, 2020, 08:22 AM</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <h4 className="text-primary">$ 5.59</h4>
                          </div>
                        </td>
                        <td>
                          <div className="">
                            <i className="fa fa-circle text-success me-1"></i>{" "}
                            Active
                          </div>
                        </td>
                        <td>
                          <DropdownBlog />
                        </td>
                      </tr>
                      <tr>
                        <td className="application_sorting_1">
                          <div className="form-check style-3">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              onClick={() => chackboxFun()}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <img
                              className="me-3 rounded-circle"
                              src={pic2}
                              alt=""
                            />
                            <div>
                              <h5 className="mb-0">Pepperoni Pizza</h5>
                              <p className="mb-0">1x </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="mb-0">June 1, 2020, 08:22 AM</p>
                          </div>
                        </td>
                        <td>
                          <div className="">
                            <p className="mb-0">June 1, 2020, 08:22 AM</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <h4 className="text-primary">$ 5.59</h4>
                          </div>
                        </td>
                        <td>
                          <div className="">
                            <i className="fa fa-circle text-success me-1"></i>{" "}
                            Active
                          </div>
                        </td>

                        <td>
                          <DropdownBlog />
                        </td>
                      </tr>
                      <tr>
                        <td className="application_sorting_1">
                          <div className="form-check style-3">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              onClick={() => chackboxFun()}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <img
                              className="me-3 rounded-circle"
                              src={pic3}
                              alt="images"
                            />
                            <div>
                              <h5 className="mb-0">Beef Burger</h5>
                              <p className="mb-0">1x </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="mb-0">June 1, 2020, 08:22 AM</p>
                          </div>
                        </td>
                        <td>
                          <div className="">
                            <p className="mb-0">June 1, 2020, 08:22 AM</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <h4 className="text-primary">$ 5.59</h4>
                          </div>
                        </td>
                        <td>
                          <div className="">
                            <i className="fa fa-circle text-danger me-1"></i>{" "}
                            Inactive
                          </div>
                        </td>

                        <td>
                          <DropdownBlog />
                        </td>
                      </tr>
                      <tr>
                        <td className="application_sorting_1">
                          <div className="form-check style-3">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              onClick={() => chackboxFun()}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <img
                              className="me-3 rounded-circle"
                              src={pic4}
                              alt="images"
                            />
                            <div>
                              <h5 className="mb-0">Japanese Ramen</h5>
                              <p className="mb-0">1x </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="mb-0">June 1, 2020, 08:22 AM</p>
                          </div>
                        </td>
                        <td>
                          <div className="">
                            <p className="mb-0">June 1, 2020, 08:22 AM</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <h4 className="text-primary">$ 5.59</h4>
                          </div>
                        </td>
                        <td>
                          <div className="">
                            <i className="fa fa-circle text-success me-1"></i>{" "}
                            Active
                          </div>
                        </td>

                        <td>
                          <DropdownBlog />
                        </td>
                      </tr>
                      <tr className="dlab-table-bottom-line">
                        <td className="application_sorting_1">
                          <div className="form-check style-3">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              onClick={() => chackboxFun()}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="media-bx d-flex py-3  align-items-center">
                            <img
                              className="me-3 rounded-circle"
                              src={pic1}
                              alt="images"
                            />
                            <div>
                              <h5 className="mb-0">Vegan Pizza</h5>
                              <p className="mb-0">1x </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="mb-0">June 1, 2020, 08:22 AM</p>
                          </div>
                        </td>
                        <td>
                          <div className="">
                            <p className="mb-0">June 1, 2020, 08:22 AM</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <h4 className="text-primary">$ 5.59</h4>
                          </div>
                        </td>
                        <td>
                          <div className="">
                            <i className="fa fa-circle text-danger me-1"></i>{" "}
                            Inactive
                          </div>
                        </td>

                        <td>
                          <DropdownBlog />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>

      <Modal
        className="modal fade"
        show={state.editMenu}
        onHide={() => dispatch({ type: "editMenu" })}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Add Menu
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => dispatch({ type: "editMenu" })}
          ></button>
        </div>
        <div className="modal-body">
          <form>
            <div className="modal-inside">
              <label htmlFor="storePicture" className="form-label mb-2">
                Item Picture
              </label>
              <div className="d-flex align-items-center mb-2">
                <img
                  src={storePicture ? storePicture : storeImage}
                  alt="Store"
                  className="add-store-image-on-modal mr-2"
                />
                {storePicture && (
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleDeleteStorePicture}
                  >
                    Delete
                  </button>
                )}
              </div>
              {!storePicture && (
                <button
                  type="button"
                  className="btn btn-primary mb-2"
                  onClick={() => storePictureRef.current.click()}
                >
                  Add Picture
                </button>
              )}
              <input
                type="file"
                className="form-control d-none"
                id="storePicture"
                accept="image/*"
                ref={storePictureRef}
                onChange={handleStorePictureChange}
              />
            </div>

            <div className="modal-inside">
              <label for="exampleInputText" className="form-label">
                Item Category
              </label>
              <select defaultValue="" className="form-control" id="sel1">
                <option disabled value="">
                  Select category
                </option>
                <option value="burger">Burger</option>
                <option value="pizza">Pizza</option>
                <option value="chinese">Chinese</option>
                <option value="thali">Thali</option>
                <option value="chicken">Chicken</option>
              </select>
            </div>
            <div className="d-flex align-items-center veg justify-content-center">
              <div className="form-check me-3 ">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                />
                <label className="form-check-label" for="flexRadioDefault1">
                  Non veg
                </label>
              </div>
              <div className="form-check style-1">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  checked
                />
                <label className="form-check-label" for="flexRadioDefault2">
                  Veg
                </label>
              </div>
            </div>
            <div className="modal-inside">
              <label for="exampleInputText" className="form-label">
                Item Title
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputText"
                placeholder=""
              />
            </div>
            <div className="row">
              <div className="col-xl-6">
                <div className="modal-inside">
                  <label for="exampleInputnumber" className="form-label mb-2">
                    Item Pricing
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="exampleInputnumber"
                  />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="modal-inside">
                  <label for="exampleInputnumber-2" className="form-label mb-2">
                    GST
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="exampleInputnumber-2"
                  />
                </div>
              </div>
            </div>
            <div className="modal-inside">
              <label for="exampleInputEmail1" className="form-label mb-2">
                Item Description
              </label>
              <textarea
                className="form-control h-auto"
                // id="val-suggestions"
                // name="val-suggestions"
                rows="4"
                placeholder=""
              />
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => dispatch({ type: "editMenu" })}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => dispatch({ type: "editMenu" })}
          >
            Update
          </button>
        </div>
      </Modal>
    </>
  );
};
export default ViewMenuTable;
